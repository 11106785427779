@font-face {
  font-family: "RubikDirt";
  src: url("./assets/fonts/RubikDirt-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Aleo";
  src: url("./assets/fonts/Aleo-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Aleo", sans-serif;
}

.RubikDirt {
  font-family: "RubikDirt", sans-serif;
}

@import "./index.scss";
