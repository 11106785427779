@keyframes rotateEffect {
    0% {
        transform: rotate(-5deg);
        /* Bắt đầu ở vị trí nghiêng sang trái */
    }

    50% {
        transform: rotate(5deg);
        /* Nghiêng sang phải */
    }

    100% {
        transform: rotate(0deg);
        /* Quay về thẳng đứng */
    }
}

.header-section {
    min-height: 900px;

    .main-content {
        width: 1287px;
        max-width: 100%;
        min-height: 892px;
        height: 100%;
        background: url("../public/brett-bg-mobile.png") no-repeat center center;
        
        @media (min-width: 1024px) {
            background: url("../public/brett-bg.png") no-repeat center center;
        }

        .text-content {
            color: rgba(0, 0, 0, 0.25);
            text-align: justify;
            font-family: Aleo;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
        }

        .address-bg {
            background: url("../public/address-bg.png") no-repeat center center;
            color: #2C2929;
            font-size: 20px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            text-transform: capitalize;

            .social-text {
                color: #6AC5FD;
                text-align: right;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;
            }
        }

    }
}

.game-play {
    .circle {
        position: absolute;
        width: 80px;
        height: 80px;
        pointer-events: none;
        transform: translate(-50%, -50%);
    }

    .welcome-state {
        .start-btn {
            background: #FFA030;
            box-shadow: 3.065px 6.129px 0px 0px #2C2929;
            display: flex;
            padding: 10px 50px;
            justify-content: center;
            align-items: center;
            gap: 6.129px;
            color: #FFF;
            text-align: right;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
        }
    }

    .time-counter {
        color: #FFF;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: uppercase;
    }

    .done-state {
        .congratulations {
            color: #FFF;
            text-align: center;
            font-family: "RubikDirt", sans-serif;
            font-size: 64px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }

        .game-record {
            color: #FFF;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .game-time {
            color: #FFF;
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-transform: uppercase;
        }

        .play-again-btn {
            background: #FFA030;
            box-shadow: 3.065px 6.129px 0px 0px #2C2929;
            display: flex;
            padding: 10px 50px;
            justify-content: center;
            align-items: center;
            gap: 6.129px;
            color: #FFF;
            text-align: right;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
        }
    }
}

.delay-1000 {
    transition-delay: 1s;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    75% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}

.shake {
    animation: shake 0.5s ease-in-out;
}